<template>
  <div class="login-container flex items-center justify-center p-20">
    <NuxtPwaManifest />
    <picture>
      <source
        media="(max-width: 767px)"
        srcset="
        ~/assets/images/loginBg/loginBg--mobile_375.png 375w,
        ~/assets/images/loginBg/loginBg--mobile_428.png 428w
        "
      >
      <source
        media="(max-width: 1219px)"
        srcset="
          ~/assets/images/loginBg/loginBg--tablet_768.png 768w,
          ~/assets/images/loginBg/loginBg--tablet_834.png 834w,
          ~/assets/images/loginBg/loginBg--tablet_1024.png 1024w
        "
      >
      <source
        media="(min-width: 1220px)"
        srcset="
          ~/assets/images/loginBg/loginBg--desktop_1440.png 1440w,
          ~/assets/images/loginBg/loginBg--desktop_1920.png 1920w
        "
      >
      <img
        class="loginBg"
        src="~/assets/images/loginBg/loginBg--desktop_1440.png"
        alt=""
      >
    </picture>
    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style>
.login-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.loginBg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
</style>
